<template>
  <div class="tech-badge-container">
      <img class="badge-style" :src="imageLink" :alt="logoName" />
      <p v-if="logoToggle" class="logo-name"> {{logoName}} </p>
  </div>
</template>

<script>
export default {
    name: "TechBadge",
    props: {
        imageLink: {
            type: String
        },
        logoName: {
            type: String 
        }
    },
    data() {
      return {
        logoToggle : this.$store.getters.toggleLogoText
      }
    },
    watch: {
      deep: true,
      '$store.state.toggleLogoText': function(newVal) {
          this.logoToggle = newVal
      }
    }
}
</script>

<style scoped>
.badge-style {
  margin: 1rem;
  padding: 0.5rem;
  background-color: #EDF0F0;
  border-radius: 10%;
  border: 4px solid #50616C;
}

.logo-name {
  text-align: center;
  margin-top: 0%;
  margin-left: 1rem;
  margin-right: 1rem;
}

@media only screen and (min-width: 320px) {
  .badge-style {
    height: 2rem;
  }
}

@media only screen and (min-width: 480px) {
  .badge-style {
    height: 3rem;
  }
}

@media only screen and (min-width: 600px) {
  .badge-style {
    height: 4rem;
  }
}

@media only screen and (min-width: 780px) {
  .badge-style {
    height: 5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .badge-style {
    height: 8rem;
  }
}
</style>