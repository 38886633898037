<template>
  <div class="education-card-container">
    <div class="education-title bold-title">
        {{degreeType}}
    </div>
    
    <div class="education-info">
        <span class="bold-title">Major:</span> {{major}}
    </div>

    <div class="education-info">
        {{insitutionName}} <br/>
        <i>{{location}}</i>
    </div>

    <div class="education-info" v-if="dateAttended">
        Date Attended: {{dateAttended}}
    </div>

    <div class="education-info" v-if="dateAttending">
        Date Attending: {{dateAttending}}
    </div>
   
  </div>
</template>

<script>
export default {
    name: "EducationCard",
    props: {
        degreeType: {
            type: String
        },
        major: {
            type: String
        },
        insitutionName: {
            type: String
        },
        location: {
            type: String    
        },
        dateAttended: {
            type: String    
        },
        dateAttending:{
            type: String
        }
    }
}
</script>

<style scoped>
.education-card-container {
    background-color: lightcyan;
    margin: 0.5rem;
    border: 5px solid #50616C;
    border-radius: 5%;
}

.education-title {
    text-align: center;
    padding: 1rem;
    background-color: #50616C;
    color: white;
}

.education-info {
    text-align: left;
    padding: 1rem;
}

.bold-title {
    font-weight: 600;
}

@media only screen and (min-width: 375px) {
    .education-card-container {
        min-height: 15vh;
        min-width: 74vw;
    }

    .education-title {
        font-size: 1rem;
    }

    .education-info {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 410px) {
    .education-card-container {
        min-height: 15vh;
        min-width: 69vw;
    }

    .education-title {
        font-size: 1rem;
    }

    .education-info {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 480px) {
    .education-card-container {
        min-width: 69vw;
    }

    .education-title {
        font-size: 1.25rem;
    }

}

@media only screen and (min-width: 540px) {
    .education-card-container {
        min-width: 50vw;
    }

    .education-title {
        font-size: 1.1rem;
    }

    .education-info {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 780px) {
    .education-card-container {
        min-width: 40vw;
    }

    .education-title {
        font-size: 1.25rem;
    }

    .education-info {
        font-size: 1.1rem;
    }
}

@media only screen and (min-width: 1024px) {
    .education-card-container {
        min-height: 25vh;
        min-width: 20vw;
    }

    .education-info {
        font-size: 1.3rem;
    }
}

</style>