<template>
    <div class="divider-bar-container" :ref="refName"></div>
</template>

<script>
import { clearAnchor } from '@/components/Methods.js'

export default {
name: "DividerBar",
    props: {
        refName: {
            type: String,
            default: 'none'
        }
    },

     watch: {
        deep: true,
        '$store.state.anchor': function(newVal) {
            console.log(newVal)
            const ref = this.$refs[newVal]
            ref.scrollIntoView({ behavior: 'smooth', block: 'center'})

            clearAnchor();
        }
    }
}
</script>

<style scoped>
.divider-bar-container {
    width: 80vw;
    height: 3vh;
    background-color: #C3D2CE ;
}
</style>