<template>
  <div class="about-the-site-container" :ref="refName">
      <p class="about-the-site-title">
          About This Site:
      </p>
      <div class="about-the-site-block">
          <span class="left-block" v-if="windowWidth > 480 ">
              <ul>
                  <li class="about-bullet-points">Framework: <b>Vue</b></li>
                  <li class="about-bullet-points">Language: <b>JavaScript ES6</b></li>
                  <li class="about-bullet-points">State Management: <b>VueX</b></li>
                  <li class="about-bullet-points">REST API: <b>Axios</b></li>
                  <li class="about-bullet-points">Styled with: <b>CSS</b>, <b>SASS</b></li>
                  <li class="about-bullet-points">Source Control: <b>Git</b></li>
                  <li class="about-bullet-points">Backend Hosting: <b>Firebase</b></li>
                  <li class="about-bullet-points">Component Count: <b>17</b></li>
              </ul>
          </span>
          <span class="right-block">
              <p class="about-detailed-points">
                  This website was created using the <b>Vue framework</b> and <b>JavaScript language</b>.
                  This is a <b>Single Page Application</b> comprised of 6 main components which
                  are: Portfolio, About Me, Education, Technologies, Random Joke, and
                  About this Site, along with 11 other components within the main 6. These
                  components are used seamlessly to appear as one page.
              </p>

              <p class="about-detailed-points">
                   State management (<b>VueX</b>) is used in the Navigation Bar as well as the
                   Technologies component. When a user clicks on one of the navigational
                   links, this triggers a function that stores a reference associated with
                   that link; for instance, if the user clicks on “Education” a reference
                   of education is stored into state. Each of the 6 main components are
                   watching for changes made to the stored referenced state. Once the stored
                   reference matches the reference for that component, a function runs that
                   scrolls the screen to the component.
              </p>

              <p class="about-detailed-points">
                  Within the Technologies component, state management controls the visibility of
                  each title associated with its logo. The Technologies component feeds the logo
                  and title for each technology into a card component and receives back the formatted
                  logo that is shown on screen. When the card is created for that logo, it sets the
                  title visibility boolean to false so that on initial view the title does not display.
                  When the user clicks on the “Toggle Titles” button from the Technologies component,
                  it changes the corresponding boolean value from false to true on each card component
                  allowing for the user to visually see the title along with the logo. 
              </p>

              <p class="about-detailed-points">
                  <b>Axois</b> is used within Random Joke component to <b>asynchronously</b> receive a 
                  random joke from https://jokeapi.dev/ with the use of a <b>Restful API</b>. When the
                  Random Joke <b>component mounts</b>, it triggers the API call that returns the joke
                  <b>JSON</b>. The JSON is parsed specifically for the joke's setup and delivery then 
                  with the use of <b>Template Literals</b>, the joke is displayed in the Random Joke component 
                  as a single string. 
              </p>

              <p class="about-detailed-points">
                  Throughout this site <b>CSS</b> is used to not only style the webpage, but to make it <b>responsive</b>
                  as well. <b>SASS</b> is used in the Technologies component, specifically with the creation of the
                  toggle button. 
              </p>

              <p class="about-detailed-points">
                  With the utilization of <b>GitHub</b> as my online repository, I was able to quickly save updates to
                  my code as I created this site with commands such as: git add ., git commit, and git push
                  origin main. This website didn’t call for much version control so there was no need for me
                  to branch out which also means there was no need to merge any branches other than current
                  code into my main branch. However, this could have easily been done with the git merge
                  command. Using Git also allowed me the flexibility to work on this site using another
                  computer by quickly pulling down the latest code by cloning the repository. 
              </p>
          </span>
      </div>
  </div>
</template>

<script>
import { clearAnchor } from '@/components/Methods.js'

export default {
    name: "AboutTheSite",
    data() {
        return {
            windowWidth: window.innerWidth
        }
    },
    props: {
        refName: {
            type: String,
            default: 'none'
        }
    },
    created() {
      window.addEventListener('resize', this.myEventHandler);
    },
    destroyed() {
      window.addEventListener('resize', this.myEventHandler);
    },
    methods: {
      myEventHandler(e) {
        e.preventDefault();
        this.windowWidth = window.innerWidth
      }
    },
    watch: {
      deep: true,
      '$store.state.anchor': function(newVal) {
          console.log(newVal)
          const ref = this.$refs[newVal]
          ref.scrollIntoView({ behavior: 'smooth', block: 'center'})

          clearAnchor();
      }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Wallpoet&display=swap');

    .about-the-site-container {
        display: grid;
        width: 80vw;
        min-height: 30vh;
        background-image: linear-gradient(to bottom, #F6FDF8,#F6FDF8);
        justify-content: center;
    }

    .about-the-site-title {
        text-align: center;
        font-size: 3rem;
        margin: 0%;
        padding: 0%;
        font-family: 'Wallpoet', cursive;
        color: #0F5A5A;
    }

    .about-the-site-block {
        display: grid;
        grid-template-columns: 0.9fr 2fr;
        width: 80vw;
        min-height: 25vh;
    }

    .left-block {
        grid-column: 1/2;
        margin: 2rem;
    }

    .about-bullet-points {
        font-size: 1.5rem;
        margin-top: 3rem;
    }

    .right-block {
        grid-column: 2/3;
        margin: 2rem;
    }

    .about-detailed-points {
        font-size: 1.5rem;
        text-align:justify;
    }

@media only screen and (min-width: 320px) {
    .about-the-site-block {
        grid-template-columns: 1fr;
    }

    .about-the-site-title {
        font-size: 2rem;
    }

    .right-block {
        margin: .75rem;
    }

    .about-detailed-points {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 480px) {
    .about-the-site-block {
        grid-template-columns: 0.9fr 2fr;
    }

    .left-block {
        margin: 0.5rem;
    }

    .about-bullet-points {
        font-size: 1rem;
        margin-top: 1rem;
    }

    .right-block {
        margin: .75rem;
    }

    .about-detailed-points {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 540px) {
    .about-the-site-title {
        font-size: 2rem;
    }

    .left-block {
        margin: .75rem;
    }

    .about-bullet-points {
        font-size: 1rem;
        margin-top: 1.5rem;
    }

    .right-block {
        margin: 1rem;
    }
}

@media only screen and (min-width: 780px) {
    .left-block {
        margin: 1rem;
    }

    .about-bullet-points {
        font-size: 1.2rem;
        margin-top: 2rem;
    }

    .right-block {
        margin: 1.5rem;
    }

    .about-detailed-points {
        font-size: 1.2rem;
        text-align:justify;
    }
}

@media only screen and (min-width: 1024px) {
    .about-the-site-title {
        font-size: 3rem;
    }

    .left-block {
        margin: 1.5rem;
    }

    .about-bullet-points {
        font-size: 1.2rem;
        margin-top: 2rem;
    }

    .right-block {
        margin: 2rem;
    }
}


@media only screen and (min-width: 1491px) {
    .left-block {
        margin: 2rem;
    }

    .about-bullet-points {
        font-size: 1.5rem;
        margin-top: 3rem;
    }

    .about-detailed-points {
        font-size: 1.5rem;
    }
}
</style>