<template>
    <div class="divider-bar-transparent-container"></div>
</template>

<script>
export default {
name: "DividerBarTransparent"
}
</script>

<style scoped>
.divider-bar-transparent-container {
    width: 100vw;
    height: 2vh;
}

</style>