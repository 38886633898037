<template>
  <div class="info-page-container">
    <div class="self-pic">
      <img class="picture-style" :src="portrait" alt="Jarred Simon"/>
    </div>
    <div class="self-info">
      <p class="self-name">Jarred Simon</p>
      <p class="self-description">~ Developer ~</p>
    </div>

  </div>
</template>

<script>
import portrait from "@/assets/jarredsimon.jpg"

export default {
    name:"InfoPage",
    data: function () {
      return {
        portrait: portrait
      }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

.info-page-container {
  display: grid;
  width: 100vw;
  min-height: 83vh;
}

.self-pic {
  margin-top: 6rem;
  justify-self: center;
  align-self: center;
}

.picture-style {
  border-radius: 30%;
  position: relative;
  z-index: 4;
}

.self-info {
  text-align: center;
}

.self-name {
  margin-top: 0;
  margin-bottom: 0%;
  font-family: 'Rock Salt', cursive;
}

.self-description {
  margin-top: 0%;
  margin-bottom: 1rem;
}

.self-name, .self-description {
  color: white;
}

@media only screen and (min-width: 320px) {
  .picture-style {
    width: 280px;
  }

  .self-name {
    font-size: 1.75rem;
  }

  .self-description {
    font-size: 2rem;
    margin-bottom: 0%;
  }

}

@media only screen and (min-width: 480px) {
  .picture-style {
    width: 400px;
  }

  .self-name {
    font-size: 2.75rem;
  }

  .self-description {
    font-size: 2.5rem;
    margin-bottom: 0%;
  }

}

@media only screen and (min-width: 540px) {
  .picture-style {
    width: 480px;
  }

}

@media only screen and (min-width: 780px) {
  .picture-style {
     width: 600px; 
  } 
}

@media only screen and (min-width: 1024px) {
  .picture-style {
    width: 725px;
    /* width: 50vw; */
  }

  .self-name {
    font-size: 5rem;
  }

  .self-description {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

}

</style>