import store from '@/store/index.js'


const setAnchor = (anchorName) => {
    store.commit('anchor', anchorName)
}

const clearAnchor = () => {
    store.commit('anchor', '')
}

const setToggleState = () => {
    let currentToggleState = store.getters.toggleLogoText
    store.commit('toggleLogoText',!currentToggleState)
}

export {
    setAnchor,
    clearAnchor,
    setToggleState
}