<template>
  <div class="joke-card-container">
      {{AJOD}}
  </div>
</template>

<script>
export default {
    name: "JokeCard",
    props:  {
        AJOD: {
            type: String
        }
    }
}
</script>

<style scoped>
    .joke-card-container {
        font-size: 1.5rem;
        text-align: center;
        border: 10px solid #50616C;
        padding: 1rem;
        background-color: lightcyan;
    }

</style>