<template>
  <div class="education-page-container" :ref="refName">
    <p class="education-title">
      Education:
    </p>
    <div class="education-block">
      <EducationCard 
        degreeType="Associates of Applied Science"
        major="Information Technology"
        insitutionName="Rose Sate College"
        location="Midwest City, Oklahoma"
        dateAttended="2015 - 2017"
      />

      <EducationCard 
        degreeType="Bachelors of Science"
        major="Information Technology"
        insitutionName="American Military University"
        location="Charles Town, West Virginia"
        dateAttended="2017 - 2019"
      />

      <EducationCard 
        degreeType="Full Stack Certification"
        major="Full Stack Programming"
        insitutionName="Code Platoon Boot Camp"
        location="Chicago, Illinois"
        dateAttended="April - December 2020"
      />

      <EducationCard 
        degreeType="Bachelors of Science"
        major="Game Design and Programming"
        insitutionName="Southern New Hampshire University"
        location="Manchester, New Hampshire"
        dateAttended="2021 - 2023"
      />

      <EducationCard 
        degreeType="Master of Science"
        major="Information Technology"
        insitutionName="Southern New Hampshire University"
        location="Manchester, New Hampshire"
        dateAttending="2023 - 2024"
      />
    </div>
  </div>
</template>

<script>
import EducationCard from './EducationCard.vue'
import { clearAnchor } from '@/components/Methods.js'

export default {
  components: { EducationCard },
    name: "EducationPage",
    props: {
        refName: {
            type: String,
            default: 'none'
        }
    },

     watch: {
        deep: true,
        '$store.state.anchor': function(newVal) {
            console.log(newVal)
            const ref = this.$refs[newVal]
            ref.scrollIntoView({ behavior: 'smooth', block: 'center'})

            clearAnchor();
        }
    }
    
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Wallpoet&display=swap');

.education-page-container {
  display: grid;
  min-height: 40vh;
  width: 80vw;
}

.education-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.education-title{
  font-size: 3rem;
  text-align: center;
  margin: 0%;
  margin-top: 1rem;
  color: white;
  font-family: 'Wallpoet', cursive;
}


@media only screen and (max-width: 385px) {
  .education-title{
    font-size: 2rem;
  }
}

</style>