

<template>
  <div class="app-container">
      <MainView />
  </div>
</template>

<script>
import MainView from '@/views/MainView.vue';

export default {
  name: "App",
  components: {
    MainView
  }  
}
</script>
<style scoped>
</style>
