<template>
  <div class="technologies-page-container" :ref="refName"> 

    <div class="technologies-title-grid">
      <span class="technologies-title">
        Technologies 
      </span>

      <span v-if="windowWidth > 820" class="toggle-button">
        <CheckBoxToggle />
        Toggle Titles
      </span>
    </div>

    <div class="technologies-sub-title">
      I have Worked With: 
    </div>

    <div v-if="windowWidth < 820" class="toggle-button">
      <CheckBoxToggle />
      Toggle Titles
    </div>

    <div class="tech-badge-block">
      <TechBadge
        :imageLink="javascript_logo"
        logoName="JavaScript"
      />

      <TechBadge
        :imageLink="html_logo"
        logoName="HTML"
      />

      <TechBadge
        :imageLink="css_logo"
        logoName="CSS"
      />

      <TechBadge
        :imageLink="reactjs_logo"
        logoName="React"
      />

      <TechBadge
        :imageLink="vue_logo"
        logoName="Vue"
      />

      <TechBadge
        :imageLink="django_logo"
        logoName="Django"
      />

      <TechBadge
        :imageLink="restapi_logo"
        logoName="RestApi"
      />

      <TechBadge
        :imageLink="git_logo"
        logoName="Git"
      />

      <TechBadge
        :imageLink="github_logo"
        logoName="GitHub"
      />

      <TechBadge
        :imageLink="nodejs_logo"
        logoName="NodeJS"
      />

      <TechBadge
        :imageLink="postgressql_logo"
        logoName="PostgresSQL"
      />

      <TechBadge
        :imageLink="firebase_logo"
        logoName="FireBase"
      />

      <TechBadge
        :imageLink="mongo_logo"
        logoName="Mongo"
      />

      <TechBadge
        :imageLink="bootstrap_logo"
        logoName="Bootstrap"
      />

      <TechBadge
        :imageLink="python_logo"
        logoName="Python"
      />

      <TechBadge
        :imageLink="java_logo"
        logoName="Java"
      />

      <TechBadge
        :imageLink="vb_logo"
        logoName="VisualBasic.Net"
      />

      <TechBadge
        :imageLink="cplusplus_logo"
        logoName="C++"
      />
    </div>
    
  </div>
</template>

<script>
import TechBadge from './TechBadge.vue'
import css_logo from "@/assets/css_logo.png"
import javascript_logo from "@/assets/javascript_logo.png"
import html_logo from "@/assets/html_logo.png"
import nodejs_logo from "@/assets/nodejs_logo.png"
import git_logo from "@/assets/git_logo.png"
import cplusplus_logo from "@/assets/cplusplus_logo.png"
import postgressql_logo from "@/assets/postgressql_logo.png"
import python_logo from "@/assets/python_logo.png"
import reactjs_logo from "@/assets/reactjs_logo.png"
import restapi_logo from "@/assets/restapi_logo.png"
import django_logo from "@/assets/django_logo.png"
import java_logo from "@/assets/java_logo.png"
import vb_logo from "@/assets/vb_logo.png"
import mongo_logo from "@/assets/mongo_logo.png"
import bootstrap_logo from "@/assets/bootstrap_logo.png"
import vue_logo from "@/assets/vue_logo.png"
import firebase_logo from "@/assets/firebase_logo.png"
import github_logo from "@/assets/github_logo.png"
import CheckBoxToggle from "@/components/CheckBoxToggle.vue"
import { clearAnchor } from '@/components/Methods.js'

export default {
  name: "TechnologiesPage",
  components: {
    TechBadge,
    CheckBoxToggle
  },
   props: {
        refName: {
            type: String,
            default: 'none'
        }
    },
    created() {
      window.addEventListener('resize', this.myEventHandler);
    },
    destroyed() {
      window.addEventListener('resize', this.myEventHandler);
    },
    methods: {
      myEventHandler(e) {
        e.preventDefault();
        this.windowWidth = window.innerWidth
      }
    },
    watch: {
      deep: true,
      '$store.state.anchor': function(newVal) {
          console.log(newVal)
          const ref = this.$refs[newVal]
          ref.scrollIntoView({ behavior: 'smooth', block: 'center'})

          clearAnchor();
      }
    },
  data() {
    return {
      css_logo: css_logo,
      javascript_logo,
      html_logo : html_logo,
      nodejs_logo : nodejs_logo,
      git_logo : git_logo,
      cplusplus_logo : cplusplus_logo,
      postgressql_logo : postgressql_logo,
      python_logo : python_logo,
      reactjs_logo : reactjs_logo,
      restapi_logo : restapi_logo,
      django_logo : django_logo,
      java_logo : java_logo,
      vb_logo : vb_logo,
      mongo_logo : mongo_logo,
      bootstrap_logo : bootstrap_logo,
      vue_logo : vue_logo,
      firebase_logo: firebase_logo,
      github_logo: github_logo,
      windowWidth: window.innerWidth
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Wallpoet&display=swap');

.technologies-page-container {
  width: 80vw;
  min-height: 30vh;
  background-image: linear-gradient(to bottom, #F6FDF8,#F6FDF8);
}

.technologies-title-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.technologies-title {
  grid-column: 2/3;
  text-align: center;
  font-size: 3rem;
  color: #0F5A5A;
  font-family: 'Wallpoet', cursive;
}

.toggle-button{
  display: grid;
  grid-column: 3/4;  
  justify-self: center;
  margin-top: 1rem;
  text-align: center;
}

.technologies-sub-title {
  font-size: 1.5rem;
  margin-top: 0%;
  font-weight: 500;
  text-align: center;
}

.tech-badge-block {
  display: flex;
  flex-wrap: wrap;
  padding-left: 8rem;
  padding-right: 8rem;
  justify-content: center;
}


@media only screen and (max-width: 480px) {
  .technologies-title {
    font-size: 2rem;
  }

  .technologies-sub-title {
    font-size: 1rem;
  }

  .tech-badge-block {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media only screen and (max-width: 820px) {
  .technologies-title {
    font-size: 2rem;
  }

  .technologies-sub-title {
    font-size: 1rem;
  }

  .tech-badge-block {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

</style>