<template>
  <div>
    <input type="checkbox" class="toggle" @click="setToggle()"/>
  </div>
</template>

<script>
import { setToggleState } from "@/components/Methods.js"

export default {
    name:"CheckBoxToggle",
    methods: {
      setToggle() {
        setToggleState()
      }
    }
}
</script>

<style lang="sass" scoped>
body
  min-height: 100vh
  display: flex
  justify-content: center
  align-items: center
  background: #141419
  
*
  box-sizing: border-box
  
input[type="checkbox"]
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
  -webkit-tap-highlight-color: transparent
  cursor: pointer
  &:focus
    outline: 0

.toggle
  height: 32px
  width: 52px
  border-radius: 16px
  display: inline-block
  position: relative
  margin: 0
  border: 2px solid #474755
  background: linear-gradient(180deg, #2D2F39 0%, #1F2027 100%)
  transition: all .2s ease
  &:after
    content: ''
    position: absolute
    top: 2px
    left: 2px
    width: 24px
    height: 24px
    border-radius: 50%
    background: white
    box-shadow: 0 1px 2px rgba(44,44,44,.2)
    transition: all .2s cubic-bezier(.5,.1,.75,1.35)
  &:checked
    border-color: #654FEC
    &:after
      transform: translatex(20px)
    
</style>