import axios from "axios";

const blacklistFlags = 'nsfw,racist,religious,political,sexist,explicit'
const category = 'Programming'
const typeOfResponse = 'twopart'

const options = {
    method: 'GET',
    url: `https://v2.jokeapi.dev/joke/${category}?blacklistFlags=${blacklistFlags}&type=${typeOfResponse}`,
    params: {
      format: 'json',
    }
  };

const get_joke_of_the_day  = () => axios.request(options).then(function (response) {
    return `${response.data.setup} ${response.data.delivery}`
    
}).catch(function (error) {
    console.error(error);
});

export {
    get_joke_of_the_day
}