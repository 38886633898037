<template>
    <div class="about-me-container" :ref="refName">
        <p class="about-me-title">About Me:</p>
        <p class="about-me-text">
            Air Force Veteran turned software engineer!
            Over the last 7 years I have been on my coding
            journey from completing various educational programs/degrees to ultimately
            landing that elusive first full-time developer position.
            I am constantly growing and learning new technologies
            along the way to better equip myself for future projects.
            I have tackled projects using several different frameworks,
            languages, and technologies. I have a passion for programming
            and enjoy collaborating within teams on the next big idea.
        </p>
    </div>
</template>

<script>
import { clearAnchor } from '@/components/Methods.js'

export default {
    name: "AboutMe",
    props: {
        refName: {
            type: String,
            default: 'none'
        }
    },

     watch: {
        deep: true,
        '$store.state.anchor': function(newVal) {
            console.log(newVal)
            const ref = this.$refs[newVal]
            ref.scrollIntoView({ behavior: 'smooth', block: 'center'})

            clearAnchor();
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Wallpoet&display=swap');

.about-me-container {
    display: grid;
    width: 80vw;
    min-height: 30vh;
    background-image: linear-gradient(to bottom, #F6FDF8,#F6FDF8);
    justify-content: center;
}

.about-me-text {
    margin: 0%;
    padding: 1rem;
    width: 55vw;
    font-size: 2rem;
    text-align: justify;
}

.about-me-title {
    text-align: center;
    font-size: 3rem;
    margin: 0%;
    margin-bottom: 1rem;
    padding: 0%;
    font-family: 'Wallpoet', cursive;
    color: #0F5A5A;
}

@media only screen and (min-width: 320px) {
    .about-me-title {
        font-size: 2rem;
    }

    .about-me-text {
        font-size: 1rem;
        width: 65vw;
        text-align:center;
    }
}

@media only screen and (min-width: 480px) {

}

@media only screen and (min-width: 540px) {
    .about-me-title {
        font-size: 2rem;
    }

    .about-me-text {
        font-size: 1.25rem;
        text-align:center;
    }
}

@media only screen and (min-width: 780px) {
    .about-me-text {
        text-align:justify;
    }
}

@media only screen and (min-width: 1024px) {
    .about-me-title {
        font-size: 3rem;
    }

    .about-me-text {
        font-size: 2rem;
    }
}

</style>