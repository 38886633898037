<template>
  <div class="main-view-container">
    <div class="sticky-top-div">
        <TopNavBar />
    </div>

    <div class="center-content" v-if="page == 'home'">
        <DividerBar refName="home"/>
        
        <InfoPage />

        <DividerBar />
        <DividerBarTransparent />
        <DividerBar />
        
        <AboutMe refName="about-me"/>
        
        <DividerBar />
        <DividerBarTransparent />
        <DividerBar />
        
        <EducationPage refName="education"/>
        
        <DividerBar/>
        <DividerBarTransparent />
        <DividerBar />
        
        <TechnologiesPage refName="technologies"/>
        
        <DividerBar />
        <DividerBarTransparent />
        <DividerBar />
        
        <JokesOnePage refName="joke"/>

        <DividerBar />
        <DividerBarTransparent />
        <DividerBar />
        
        <AboutTheSite refName="about-the-site"/>

        <DividerBar />
        <DividerBarTransparent />
        
        <FooterPage />
    </div>

    <div v-else-if="page == 'epic'">
        <DividerBar />
        <DividerBarTransparent />
        <DividerBarTransparent />
        <MainEpicGamesPage />
        <DividerBarTransparent />
        <DividerBarTransparent />
    </div>

  </div>
</template>

<script>
import DividerBar from '@/components/DividerBar.vue';
import InfoPage from '@/components/InfoPage.vue';
import TopNavBar from '@/components/TopNavBar.vue';
import DividerBarTransparent from '@/components/DividerBarTransparent.vue';
import AboutMe from '@/components/AboutMe.vue';
import EducationPage from '@/components/EducationPage.vue';
import TechnologiesPage from '@/components/TechnologiesPage.vue';
import FooterPage from '@/components/FooterPage.vue';
import MainEpicGamesPage from '@/views/MainEpicGamesPage.vue';
import JokesOnePage from "@/components/JokesOne/JokesOnePage.vue"
import AboutTheSite from '@/components/AboutTheSite.vue';

export default {
    name: "MainView",
    components: {
        TopNavBar,
        DividerBar,
        InfoPage,
        DividerBarTransparent,
        AboutMe,
        EducationPage,
        TechnologiesPage,
        FooterPage,
        MainEpicGamesPage,
        JokesOnePage,
        AboutTheSite
    },
    data() {
        return {
            page: 'home'
        }
    },
    methods: {
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
    },
    beforeMount: function() {
        this.isMobile()
    }
}
</script>

<style scoped>
.main-view-container {
    overflow-x: hidden;
    background-image: url('~@/assets/background_five.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.center-content {
    display: grid;
    justify-items: center;
}

.sticky-top-div {
    position: fixed;
    z-index: 999;
}

@media only screen and (max-width: 480px) {
    .main-view-container {
        background-attachment: scroll;
        background-repeat: repeat-y;
    }
}

@media only screen and (max-width: 850px) { 
    .main-view-container {
        background-repeat: repeat-y;
    }
}
</style>