<template>
  <div class="main-container" :key="containerKey">
      <div v-for="game in gameList" :key="game.id">
          <img class="game-image" :src="game.keyImages['0'].url" alt="game image">
      </div>
  </div>
</template>

<script>
import { getGames } from "@/components/FreeEpicGames/ApiCall.js"


export default {
    name: "MainEpicGamesPage",
    data() {
        return{
            gameList: [],
            containerKey: 0
        }
    },
    methods: {
        async getGameInfo () {
            this.gameList = await getGames()
            console.log(this.gameList)
        }
    },
    mounted: function () {
        this.getGameInfo()
    }
}

</script>

<style scoped>
.main-container {
    display: grid;
    /* flex-wrap: wrap; */
    width: 100vw;
    overflow-x: hidden;
    height: 100vh;
    justify-content: center;
}

.game-image {
    width: 20vw;
    margin: 1rem;
}
</style>