import axios from "axios";

const  options= {
    method: 'GET',
    url: 'https://free-epic-games.p.rapidapi.com/free',
    headers: {
        'X-RapidAPI-Host': 'free-epic-games.p.rapidapi.com',
        'X-RapidAPI-Key': 'a36c1125aemshc86efb944b5c50cp1d4acfjsn4783fab63365'
    }
}

const getGames = () => axios.request(options).then(function (response) {
    console.log(response.data.freeGames.current);
    return response.data.freeGames.current
    
}).catch(function (error) {
    console.error(error);
});

export {
    getGames
}