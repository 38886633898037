<template>
  <div class="footer-page-container">
  </div>
</template>

<script>
export default {
    name: "FooterPage"
}
</script>

<style scoped>
.footer-page-container {
    width: 80vw;
    height: 1vh;
    background-color: #C3D2CE ;
}

@media only screen and (max-width: 480px) {
  .footer-page-container {
    height: 1vh;
  }

}

@media only screen and (max-width: 820px) {
  .footer-page-container {
    height: 1vh;
  }
}
</style>