<template>
  <div class="top-nav-bar-container">
    <span class="left-block">
      <span class="portfolio-title" @click="setStoreAnchor('home')">Portfolio</span>

      <span v-if="windowWidth > setScreenWidth">
        <span v-for="link in navLinks" :key="link.id">
          <span :class="link.className" @click="setStoreAnchor(link.anchor)">
            {{link.title}}
          </span> 
          <span v-if="link.id != navLinks.length - 1">|</span>
        </span>
      </span>

      <span class="nav-bars" v-if="windowWidth <= setScreenWidth">
        <img class="bars-style" @click="setNavDropDownToggle()" :src="solidBarsIcon" alt="navigation icon" />
      </span>
    </span>
    
    <div class="dropdown-content" v-if="windowWidth <= setScreenWidth && toggleNavDropDown">
      <span class="dropdown-titles" v-for="link in navLinks" :key="link.id">
        <span @click="setStoreAnchor(link.anchor, true)">
          {{link.title}}
        </span> 
      </span>
    </div>

    <span class="right-block">
      <span v-if="showResumeToolTip && windowWidth > setScreenWidth" class="hover-text">Download Resume</span>
      <span v-if="showLinkedinToolTip && windowWidth > setScreenWidth" class="hover-text">Open LinkedIn Page</span>
      
      <span @mouseover="showResumeToolTip = true" @mouseout="showResumeToolTip = false"> 
        <a :href="resumeURL" download>
          <img class="nav-icons" :src="resumeIcon" alt="Resume Icon"/>
        </a>
      </span>

      <span @mouseover="showLinkedinToolTip = true" @mouseout="showLinkedinToolTip = false">
        <a :href="linkedInURL" target="_blank">
          <img class="nav-icons" :src="linkedinIcon" alt="LinkedIn Icon"/>
        </a>
      </span>
    </span>  
  </div>
</template>

<script>
import linkedinIcon from "@/assets/linkedin_icon.png"
import resumeIcon from "@/assets/resume_icon.png"
import { setAnchor } from '@/components/Methods.js'
import solidBars from "@/assets/bars-solid.svg"

export default {
    name: "TopNavBar",
    data() {
      return{
        linkedinIcon: linkedinIcon,
        resumeIcon: resumeIcon,
        solidBarsIcon: solidBars,
        showResumeToolTip: false,
        showLinkedinToolTip: false,
        linkedInURL: 'https://www.linkedin.com/in/jarred-simon/',
        resumeURL: 'Jarred_Simon_Resume.pdf',
        windowWidth: window.innerWidth,
        setScreenWidth: 765,
        toggleNavDropDown: false,
        navLinks: [
          {
            id: '0',
            title:'About Me',
            className:'about-me-title',
            anchor:'about-me'
          },
          {
            id: '1',
            title:'Education',
            className:'education-title',
            anchor:'education'
          },
          {
            id: '2',
            title:'Technologies',
            className:'technologies-title',
            anchor:'technologies'
          },
          {
            id: '3',
            title:'Random Joke',
            className:'joke-of-the-day-title',
            anchor:'joke'
          },
          {
            id: '4',
            title:'About this Site',
            className:'about-the-site-title',
            anchor:'about-the-site'
          }
        ]
      }
    },
    created() {
      window.addEventListener('resize', this.myEventHandler);
    },
    destroyed() {
      window.addEventListener('resize', this.myEventHandler);
    },
    methods: {
      setStoreAnchor (anchorName, clearDropdown = false) {
        setAnchor(anchorName)
        if (clearDropdown) {
          this.setNavDropDownToggle()
        }
      },

      setNavDropDownToggle() {
        this.toggleNavDropDown = !this.toggleNavDropDown
      },

      myEventHandler(e) {
        e.preventDefault();
        this.windowWidth = window.innerWidth
      }
    }
}
</script>

<style scoped>
.top-nav-bar-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  min-height: 6vh;
  width: 100vw;
  background-image: linear-gradient(to right, #ECF6F3,#18392E);
}

.left-block {
  margin-left: 1rem;
  justify-self: start;
  align-self: center;
}

.right-block {
  margin-right: 2rem;
  justify-self: end;
  align-self: center;
}

.portfolio-title {
  font-size: 1.5rem;
  background-image: linear-gradient(to left, #244531,#6C716E);
  color: white;
  font-weight: 600;
  padding: 0.25rem;
  cursor: pointer;
}

.portfolio-title, .about-me-title, .education-title, .technologies-title, .joke-of-the-day-title, .about-the-site-title, .demo-zone-title {
  margin: 1rem;
  font-weight: 600;
}

.about-me-title, .education-title, .technologies-title, .joke-of-the-day-title, .about-the-site-title, .demo-zone-title {
  margin: 1rem;
  font-weight: 600;
  font-size: 1.1rem;
}

.about-me-title:hover, .education-title:hover, .technologies-title:hover, .joke-of-the-day-title:hover, .about-the-site-title:hover, .demo-zone-title:hover  {
  color: #0F6432;
  cursor: pointer;
  text-decoration-line: underline;
}

.nav-icons {
  width: 2rem;
  margin: .5rem;
  cursor: pointer;
}

.nav-bars {
  position: relative;
  cursor: pointer;
  margin: 0.5rem;
  top: 0.4rem;
}

.bars-style {
  width: 1.5rem;
}

.hover-text {
  color: white;
  font-size: 1rem;
  padding: 1rem;
  color: white;
  background-color: #052512;
  border: 3px solid gray;
  border-radius: 05%;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 3.2rem;
  left: 8.5rem;
  background-image: linear-gradient(to right, #ECF6F3,#74B0B0);
}

.dropdown-titles {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  border: 1px solid black;
}

.dropdown-titles:hover {
  cursor: pointer;
  background-color: #6C716E;
}

@media only screen and (max-width: 1109px) {
 .portfolio-title, .about-me-title, .education-title, .technologies-title, .joke-of-the-day-title, .about-the-site-title, .demo-zone-title {
    margin: 0.5rem;
    font-weight: 600;
  }

  .about-me-title, .education-title, .technologies-title, .joke-of-the-day-title, .about-the-site-title, .demo-zone-title {
    font-weight: 600;
    font-size: .75rem;
  }
}

@media only screen and (max-width: 511px) {
  .top-nav-bar-container {
    grid-template-columns: 1fr 1fr;
  }
}
</style>