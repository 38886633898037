<template>
  <div class="jokes-container" :ref="refName">
      <div class="joke-title">
          Random Joke:
      </div>
      <div class="joke-block">
        <JokeCard :AJOD="jod"/>
      </div>
  </div>
</template>

<script>
import { get_joke_of_the_day } from '@/components/JokesOne/JokesOneApi.js'
import JokeCard from './JokeCard.vue'
import { clearAnchor } from '@/components/Methods.js'

export default {
    name:"JokesOnePage",
    props: {
        refName: {
            type: String,
            default: 'none'
        }
    },
    data() {
        return {
            jod: ''
        }
    },
    components: { 
        JokeCard
    },
    methods: {
        async getJOD() {
            this.jod = await get_joke_of_the_day()
        }
    },
    watch: {
      deep: true,
      '$store.state.anchor': function(newVal) {
          console.log(newVal)
          const ref = this.$refs[newVal]
          ref.scrollIntoView({ behavior: 'smooth', block: 'center'})

          clearAnchor();
      }
    },
    mounted: function () {
        this.getJOD()
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Wallpoet&display=swap');

.jokes-container {
    width: 60vw;
    min-height: 20vh;
}

.joke-title {
    text-align: center;
    font-size: 3rem;
    color: white;
    font-family: 'Wallpoet', cursive;
    margin-top: 1rem;
}

.joke-block {
    padding: 1rem;
}

@media only screen and (max-width: 554px) {
    .joke-title {
        font-size: 2.5rem;
    }
}

</style>